import React, { useState, useMemo } from 'react';
import { getColumn, handleConfirmStone, handleDownloadExcel, isMobile } from '../../DiamondList/DiamondListFunctions';
import invert from 'lodash/invert';
import size from 'lodash/size';
import Table from '../../DiamondList/TableBack';
import SelectOption from '../SelectOption';
import Heading from '../Heading';
import InputBlock from '../InputBlock';
import OpenNotification from '../CommonButton/OpenNotification';
import { useDispatch, useSelector } from 'react-redux';
import { calculate, formatNumber } from './SelectStone';
import SendEmailPopup from './SendEmail';
import { LOCAL_STORAGE_VAR, SELECT_STONE_TERMS_COD, SELECT_STONE_TERMS_NEW } from '../../../constants/Common';
import MobileConfirmStone from './MobileComfirmStone';
import TextArea from '../TextArea';
import IntlMessages from '../../../util/IntlMessages';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import { DiamondActions } from 'appRedux/reducers/diamond';
// import { user } from "../../../constants/Common";

export const savedSearch = {
  saleman: [{ value: 'John Doe' }, { value: 'John Doe' }],
  invoicDate: [
    { id: '1', name: 'Today' },
    { id: '2', name: 'Tommorrow' },
    { id: '3', name: 'Later' },
  ],
  country: [{ value: 'India' }, { value: 'Hong Kong' }, { value: 'US' }],
  city: [{ value: 'Gujarat' }, { value: 'baroda' }, { value: 'Vapi' }],
  billType: [{ value: 'Dollar Bill' }],
  couriername: [{ value: 'Any' }, { value: 'Malca Amit4' }],
  sale: [{ value: 'Idex Ok' }, { value: 'Blue Nile Ok' }],
  terms: [{ value: '7 Days' }, { value: 'Advance' }, { value: 'COD' }],
  assi: [{ value: 'Vishal Virani' }, { value: 'Vishal Virani' }, { value: 'Vishal Virani' }],
};
const currentType = 'ConfirmStonePopup';

export function HeadingCalc(checked, VALUES = null, isBidDis) {
  if (isMobile() && !checked.length && !VALUES) return null;
  let obj = {};
  if (VALUES) obj = VALUES;
  else {
    const disc = localStorage.getItem('disc');
    const sum = calculate(checked, null, isBidDis);
    obj = {
      'Pieces:': sum.total_pieces,
      'Crt:': parseFloat(sum.total_carat).toFixed(2),
      'Disc% :': parseFloat(sum.final_discount).toFixed(2),
      'Pr/Ct :': parseFloat(sum.final_price).toFixed(2),
      'Amt. :': parseFloat(sum.bid_final_amt).toFixed(2),
    };
  }
  // if (isMobile()) obj = invert(obj);
  if (size(obj) < 4) return null;
  return (
    <div className="DiamondDetailPopup">
      {Object.keys(obj).map((x) => {
        return (
          <div className="DiamondDetailPopupItem" style={VALUES ? checked : {}}>
            <span>{x}</span>
            <span>
              {isMobile()
                ? x === 'Bid Term Discount(%)' || x === 'Avg. Discount(%)' || x === 'TERMS'
                  ? obj[x] > 0
                    ? `+${obj[x]}`
                    : obj[x]
                  : obj[x]
                : obj[x]}
            </span>
          </div>
        );
      })}
    </div>
  );
}

const ConfirmStonePopup = (props) => {
  const user = JSON.parse(window.atob(localStorage.getItem(`${LOCAL_STORAGE_VAR}-user`)));
  const term = localStorage.getItem('Terms');
  const [comment, setComment] = useState('');
  const [company, setCompany] = useState(user && user.account ? user.account.companyName : '');
  const [date, setDate] = useState('1');
  const [termCal, setTermCal] = useState(term === 'true' ? '' : term);
  const [cm, setCm] = useState(false);
  const [fm, setFm] = useState('COD');
  const [columns, setColumns] = useState([]);
  const [settings, setSettings] = useState([]);
  const checked = useSelector((state) => state.diamondData.selectedRows[currentType]) || [];
  // const checked = props?.checked || [];
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (user?.account?.isCOD === true) {
      localStorage.setItem('Terms', false);
      setTermCal(SELECT_STONE_TERMS_COD[0].id);
    }
    return () => dispatch(DiamondActions.resetSelectedRows({ currentType }));
  }, [])

  const getFMCharge = ({ shpNm, crt, clrNm, colNm }) => {
    let charge = 0;
    if (settings.length) {
      const grp = groupBy(settings, 'shp');
      const shaped = shpNm === 'ROUND' ? grp['ROUND'] : grp['FANCY'];
      const carated = shaped.filter((el) => (el.from ? el.from <= crt : true) && (el.to ? el.to >= crt : true));
      if (carated.length) {
        let found = carated.filter((el) => !el.clr && !el.col);
        if (!found.length)
          found = carated.filter(
            (el) =>
              (el.col && el.col.split(',').includes(colNm) ? true : false) &&
              (el.clr && el.clr.split(',').includes(clrNm) ? true : false),
          );
        if (found.length) charge = found[0].charges;
      }
    }
    return charge;
  };

  const getTerms = () => {
    return (
      <div>
        <select
          name="termCal"
          value={termCal}
          placeholder="Select Terms"
          onChange={(e) => {
            // let s = calculate(checked, 2)
            localStorage.setItem('Terms', false);
            console.log(e.target.value);

            setTermCal(e.target.value);
          }}
        >
          {/* <option value='00' disabled>Select Terms</option> */}

          {!user?.account?.isCOD ?
            <option value="" disabled selected hidden>
              Select Terms
            </option> : null}
          {user?.account?.isCOD
            ? SELECT_STONE_TERMS_COD.map((x) => (<option value={x.id}>{x.name}</option>))
            : SELECT_STONE_TERMS_NEW.map((x) => (
              <option value={x.id}>{x.name}</option>
            ))}
        </select>
      </div>
    );
  };

  const getCmCharge = (arr) => {
    let charge = 0;
    arr.map((line) => {
      if (line.isCm && line.isCm === 'ELIG') {
        const found = settings.filter((el) => el.from <= line.crt && (el.to ? el.to >= line.crt : true));
        if (found.length) charge += found[0].fee;
      }
      return true;
    });
    return charge;
  };

  const newPricedRows = checked; //.map(x => newDiamondPrice(x, 'charge' + fm))
  // let summation = calculate(newPricedRows);
  const summation = useMemo(() => calculate(newPricedRows, termCal), [checked, termCal]);
  const finalValue = parseFloat(summation.final_net_value).toFixed(2);
  const promotionalDis = props.promotionDis?.data || 0;
  const showDis = props.showDis?.data;
  // const maxAmt = Math.round((parseFloat(props.promotionDis?.maxAmt) * promotionalDis) / 100);

  const totalPieces = summation.total_pieces;
  const totalCarat = parseFloat(summation.total_carat).toFixed(2);
  const rapPrice = summation.final_rapaport;
  const avgDisc = parseFloat(summation.final_discount).toFixed(2);
  const avgPr = summation.final_price;

  const netValue = formatNumber(parseFloat(summation.final_value).toFixed(2));

  const addDis = parseFloat(summation.addDis).toFixed(2);
  const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
  localStorage.setItem('TermsDis', termDisc);
  const sysDis_with_positive = summation.sysDis_with_positive;

  const sysDis = summation.sysDis;
  const finalNetRate = formatNumber(parseFloat(summation.final_rate).toFixed(2));
  //let sysAmtDisc = Number(summation.final_value) * (Number(summation.sysDis) / 100);
  const finalAvgDisc = parseFloat(Number(summation.final_back)).toFixed(2);
  const finalNetValue = formatNumber(parseFloat(summation.final_net_value).toFixed(2));
  //let CmCharge = parseFloat(getCmCharge(newPricedRows)).toFixed(2);
  const terms = getTerms();
  const promDiscAmount = parseFloat(Math.abs((finalValue * promotionalDis) / 100).toFixed(2));
  const showDiscAmount = parseFloat(Math.abs((finalValue * showDis) / 100).toFixed(2));

  // const profinalNetValue =
  //   promDiscAmount > props.promotionDis?.maxAmt
  //     ? parseFloat(finalValue) - props.promotionDis?.maxAmt
  //     : parseFloat(finalValue) - promDiscAmount;

  let profinalNetValue;

  if (props?.location?.pathname === '/show' && props?.showDis?.data !== 0) {
    if (showDiscAmount > props.showDis?.maxAmt) {
      profinalNetValue = parseFloat(finalValue) - props.showDis?.maxAmt;
    } else {
      profinalNetValue = parseFloat(finalValue) - showDiscAmount;
    }
    // console.log(profinalNetValue)
  } else {
    if (promDiscAmount > props.promotionDis?.maxAmt) {
      profinalNetValue = parseFloat(finalValue) - props.promotionDis?.maxAmt;
    } else {
      profinalNetValue = parseFloat(finalValue) - promDiscAmount;
    }
  }

  const profinalNetCtPr = parseFloat(parseFloat(profinalNetValue) / parseFloat(totalCarat)).toFixed(2);
  let promoDis = null;

  if (props.location?.pathname === '/show' && props.showDis.data !== 0) {
    promoDis = parseFloat(props?.showDis?.data);
  } else {
    promoDis = parseFloat(props.promotionDis?.data);
  }

  const VALUES = isMobile()
    ? {
      'Total Pieces': totalPieces === undefined ? 0.0 : totalPieces,
      'Total Carats:': totalCarat === 'NaN' ? '0.00' : totalCarat,
      'Rap Prices': rapPrice || '0.00',
      'Avg. Discount(%)': avgDisc === 'NaN' ? '0.00' : <b>{avgDisc}</b>,
      'Total Pr/Ct': avgPr || '0.00',
      'Net Value': netValue === 'NaN' ? '0.00' : netValue,
      'Amt Discount(%)': sysDis === undefined ? '0.00' : <b>{sysDis}</b>,
      'Terms Discount(%)':
        addDis === 'NaN' ? (
          '0.00'
        ) : Number(addDis) <= 0 ? (
          <div className={`${addDis === '0.00' ? '' : 'bold-text'}`}>{addDis}</div>
        ) : (
          `+${addDis}`
        ),
      'Final Discount(%)':
        props.promotionDis?.data === 0 &&
          parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'NaN' ? (
          '0.00'
        ) : props.promotionDis?.data ? (
          <b>{parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2)}</b>
        ) : (
          <b>{finalAvgDisc}</b>
        ),
      'Promotional Dis(%)': <b>{promoDis}</b>,
      'Final Net Rate':
        props.promotionDis?.data === 0 && formatNumber(profinalNetCtPr) === 'NaN' && finalNetRate === 'NaN'
          ? '0.00'
          : props.promotionDis?.data !== 0 || props.showDis?.data !== 0
            ? formatNumber(profinalNetCtPr)
            : finalNetRate,
      // 'Final Net Value': finalNetValue + (cm && CmCharge > 0 ? ' + $' + CmCharge + ' (CM Charges)' : ''),
      TERMS: terms,
    }
    : [
      { title: 'Total Pieces', value: totalPieces === undefined ? 0.0 : totalPieces },
      { title: 'Amt Discount(%)', value: sysDis === undefined ? '0.00' : <b>{sysDis}</b> },
      { title: 'Rap Prices', value: rapPrice || '0.00' },
      {
        title: 'Terms Discount(%)',
        value:
          addDis === 'NaN' ? (
            '0.00'
          ) : Number(addDis) <= 0 ? (
            <div className={`${addDis === '0.00' ? '' : 'bold-text'}`}>{addDis}</div>
          ) : (
            `+${addDis}`
          ),
      },
      { title: 'Avg. Discount(%)', value: avgDisc === 'NaN' ? '0.00' : <b>{avgDisc}</b> },

      {
        title: 'Final Discount(%)',
        value:
          // props.promotionDis?.data === 0 &&
          (props?.currentType === '/show' &&
            parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'NaN') ||
            parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'NaN' ||
            parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2) === 'Infinity' ? (
            '0.00'
          ) : props.promotionDis?.data !== 0 || props.showDis?.data !== 0 ? (
            <b>{parseFloat((1 - profinalNetCtPr / parseFloat(rapPrice)) * -100).toFixed(2)}</b>
          ) : (
            <b>{finalAvgDisc}</b>
          ),
      },
      { title: 'Total Pr/Ct', value: avgPr || '0.00' },
      {
        title: 'Final Net Rate',
        value:
          (props.currentType === '/show' && formatNumber(profinalNetCtPr) === 'NaN' && finalNetRate === 'NaN') ||
            (formatNumber(profinalNetCtPr) === 'NaN' && finalNetRate === 'NaN')
            ? '0.00'
            : props.promotionDis?.data || props.showDis?.data
              ? formatNumber(profinalNetCtPr)
              : finalNetRate,
      },
      { title: 'Total Carats', value: totalCarat === 'NaN' ? '0.00' : totalCarat },

      {
        title: 'Final Net Value',
        value:
          (props.promotionDis?.data === 0 && formatNumber(profinalNetValue) === NaN) ||
            ('NaN' && finalNetValue === 'NaN')
            ? '0.00'
            : props.promotionDis?.data !== 0 || props.showDis?.data !== 0
              ? formatNumber(profinalNetValue)
              : finalNetValue,
      },
      { title: 'Net Value', value: netValue === 'NaN' ? '0.00' : netValue },
      // props.location?.pathname === '/show' && props.showDis.data !== 0
      //   ? { title: 'promotional Dis(%)', value: promoDis }
      //   : props?.promotionDis?.data !== 0 && props.location?.pathname !== '/show'
      //   ? { title: 'promotional Dis(%)', value: promoDis }
      //   : '',

      props.location?.pathname === '/show' && props.showDis.data !== 0
        ? { title: 'promotional Dis(%)', value: promoDis }
        : props?.promotionDis?.data !== 0 && props.location?.pathname !== '/show'
          ? { title: 'promotional Dis(%)', value: promoDis }
          : { title: 'promo. Discount(%)', value: '0.00' },
      {
        title: 'TERMS',
        value: terms,
      },
    ];

  const [email, sendEmail] = useState('');

  const getcompany = () => {
    return (
      <IntlMessages id="app.CompanyName*">
        {(placeholder) => (
          <InputBlock
            label={<IntlMessages id="app.CompanyName*" />}
            placeholder={placeholder}
            value={company}
            disabled
          // onChange={e => setCompany(e.target.value)}
          />
        )}
      </IntlMessages>
    );
  };
  const getinvoicedate = () => {
    return (
      <SelectOption
        selectValue={savedSearch.invoicDate}
        placeholder="Invoice Date"
        label="Select Invoice Date*"
        value={date}
        onChange={(e) => setDate(e)}
      />
    );
  };
  const checkCheck = () => {
    if (!checked.length) {
      OpenNotification({
        type: 'error',
        title: 'Please select stone(s) to confirm.',
      });
      return false;
    } else return true;
  };
  const submit = () => {
    const selectTerm = localStorage.getItem('Terms');
    if (!date) {
      OpenNotification({ type: 'error', title: 'Please select invoice date.' });
      return;
    } else if (!company) {
      OpenNotification({ type: 'error', title: 'Please enter company name.' });
      return;
    } else if (selectTerm === 'true' || !termCal) {
      OpenNotification({
        type: 'error',
        title: 'You need to select term before confirm',
      });
    } else if (!checkCheck()) {
      return;
    } else {
      handleConfirmStone(
        checked.map((x) => x.id),
        comment,
        date,
        company,
        props.promotionDis?.data,
        props.showDis?.data,
        (flag) => {
          props.onClose();
          if (props.clearAll) props.clearAll();
          if (flag && props.fetch) props.fetch();
        },
      );
    }
  };
  const cancel = () => {
    props.onClose();
    if (props.clearAll) props.clearAll();
  };
  const gettextarea = () => {
    return isMobile() ? (
      <IntlMessages id="app.comment">
        {(placeholder) => (
          <TextArea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            label={<IntlMessages id="app.comment" />}
            placeholder={placeholder}
          />
        )}
      </IntlMessages>
    ) : (
      <textarea value={comment} onChange={(e) => setComment(e.target.value)} />
    );
  };

  if (isMobile())
    return (
      <MobileConfirmStone
        data={props.checked}
        VALUES={VALUES}
        currentType={currentType}
        parent={{
          getcompany,
          getinvoicedate,
          submit,
          cancel,
          gettextarea,
          checkCheck,
        }}
      />
    );

  const getTotalNetAmount = (data) => {
    let totalNetAmount = 0;
    if (data && data?.length > 0) {
      const res = data?.forEach((cell) => {
        const amnt = cell.amt;
        const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
        const amntPer = (amnt * sysDis_with_positive) / 100;
        const amntafterSysDis = amnt - amntPer;
        const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

        const netAmountAfterTermDisc =
          termDisFromAmtafterSyaDis < 0
            ? amntafterSysDis + termDisFromAmtafterSyaDis
            : amntafterSysDis - termDisFromAmtafterSyaDis;

        totalNetAmount += netAmountAfterTermDisc;
        return;
      });
    }
    return totalNetAmount;
  };

  // eslint-disable-next-line
  React.useEffect(() => {
    const Columns = getColumn();
    let index = findIndex(Columns, { id: 'rap' });
    if (!index) index = 1;
    const columns = [
      ...Columns.slice(0, index + 1).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
      find(Columns, { id: 'back' }),
      find(Columns, { id: 'ctPr' }),
      find(Columns, { id: 'amt' }),
      // find(Columns, { id: 'msrmnt' }),
      {
        Header: 'Net Discount',
        accessor: 'netDis',
        id: 'netDis',
        Cell: ({ cell }) => {
          const amnt = cell.row.original.amt;
          const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
          const amntPer = (amnt * sysDis_with_positive) / 100;
          const amntafterSysDis = amnt - amntPer;
          const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

          const netAmountAfterTermDisc =
            termDisFromAmtafterSyaDis < 0
              ? amntafterSysDis + termDisFromAmtafterSyaDis
              : amntafterSysDis - termDisFromAmtafterSyaDis;

          const promDisc =
            props.promotionDis?.data !== 0
              ? Math.abs((netAmountAfterTermDisc * props?.promotionDis?.data) / 100)
              : props.showDis?.data !== 0
                ? Math.abs((netAmountAfterTermDisc * props?.showDis?.data) / 100)
                : 0;

          const totalNetAmount = getTotalNetAmount(props?.checked);

          let netAmount;

          if (props?.location?.pathname === '/show' && props?.showDis?.data !== 0) {
            if (showDiscAmount > props.showDis?.maxAmt) {
              if (props.diamondData?.selectedRows['ConfirmStonePopup']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.showDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.showDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - showDiscAmount;
            }
          } else if (props.promotionDis && props.promotionDis?.data !== 0) {
            if (promDisc > props.promotionDis?.maxAmt) {
              if (props.diamondData?.selectedRows['ConfirmStonePopup']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.promotionDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.promotionDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - promDisc;
            }
          } else {
            netAmount = netAmountAfterTermDisc
          }
          const netPriceperCarat = netAmount / cell.row.original.crt;
          const netPriceCrtRap = netPriceperCarat / cell.row.original.rap;
          const netDiscount = netPriceCrtRap > 1 ? -(1 - netPriceCrtRap) * 100 : (1 - netPriceCrtRap) * 100;
          return (
            <>
              {isNaN(netDiscount) ? '0.00' : `${netPriceCrtRap > 1 ? '+' : '-'} ${parseFloat(netDiscount).toFixed(2)}`}
            </>
          );
          // return <>1233</>;
        },
      },
      {
        Header: 'Net Price/ct',
        accessor: 'netPriceCt',
        id: 'netPriceCt',
        Cell: ({ cell }) => {
          const amnt = cell.row.original.amt;
          const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
          const amntPer = (amnt * sysDis_with_positive) / 100;
          const amntafterSysDis = amnt - amntPer;
          const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

          const netAmountAfterTermDisc =
            termDisFromAmtafterSyaDis < 0
              ? amntafterSysDis + termDisFromAmtafterSyaDis
              : amntafterSysDis - termDisFromAmtafterSyaDis;

          const promDisc =
            props.promotionDis?.data !== 0
              ? Math.abs((netAmountAfterTermDisc * props?.promotionDis?.data) / 100)
              : props.showDis?.data !== 0
                ? Math.abs((netAmountAfterTermDisc * props?.showDis?.data) / 100)
                : 0;

          const totalNetAmount = getTotalNetAmount(props?.checked);
          let netAmount;

          if (props?.location?.pathname === '/show' && props?.showDis?.data !== 0) {
            if (showDiscAmount > props.showDis?.maxAmt) {
              if (props.diamondData?.selectedRows['ConfirmStonePopup']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.showDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.showDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - showDiscAmount;
            }
          } else if (props.promotionDis && props.promotionDis?.data !== 0) {
            if (promDisc > props.promotionDis?.maxAmt) {
              if (props.diamondData?.selectedRows['ConfirmStonePopup']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.promotionDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.promotionDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - promDisc;
            }
          } else {
            netAmount = netAmountAfterTermDisc
          }
          const netPriceperCarat = netAmount / cell.row.original.crt;

          return <>{isNaN(netPriceperCarat) ? '0.00' : parseFloat(netPriceperCarat).toFixed(2)}</>;
        },
      },
      {
        Header: 'Net Amount',
        accessor: 'netAmo',
        id: 'netAmo',
        Cell: ({ cell }) => {
          const amnt = cell.row.original.amt;
          const termDisc = Number(addDis) <= 0 ? addDis : `+${addDis}`;
          const amntPer = (amnt * sysDis_with_positive) / 100;
          const amntafterSysDis = amnt - amntPer;
          const termDisFromAmtafterSyaDis = (amntafterSysDis * termDisc) / 100;

          const netAmountAfterTermDisc =
            termDisFromAmtafterSyaDis < 0
              ? amntafterSysDis + termDisFromAmtafterSyaDis
              : amntafterSysDis - termDisFromAmtafterSyaDis;

          const promDisc =
            props.promotionDis?.data !== 0
              ? Math.abs((netAmountAfterTermDisc * props?.promotionDis?.data) / 100)
              : props.showDis?.data !== 0
                ? Math.abs((netAmountAfterTermDisc * props?.showDis?.data) / 100)
                : 0;

          const totalNetAmount = getTotalNetAmount(props?.checked);

          let netAmount;

          if (props?.location?.pathname === '/show' && props?.showDis?.data !== 0) {
            if (showDiscAmount > props.showDis?.maxAmt) {
              if (props.diamondData?.selectedRows['ConfirmStonePopup']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.showDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.showDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - showDiscAmount;
            }
          } else if (props.promotionDis && props.promotionDis?.data !== 0) {
            if (promDisc > props.promotionDis?.maxAmt) {
              if (props.diamondData?.selectedRows['ConfirmStonePopup']?.length === 1) {
                netAmount = parseFloat(netAmountAfterTermDisc) - props.promotionDis?.maxAmt;
              } else {
                const disc = (netAmountAfterTermDisc / totalNetAmount) * props.promotionDis?.maxAmt;
                const updatedNetAmount = netAmountAfterTermDisc - disc;
                netAmount = updatedNetAmount;
              }
            } else {
              netAmount = parseFloat(netAmountAfterTermDisc) - promDisc;
            }
          } else {
            netAmount = netAmountAfterTermDisc
          }
          return <>{isNaN(netAmount) ? '0.00' : parseFloat(netAmount).toFixed(2)}</>;
        },
      },
      // {
      //   Header: "Final Disc",
      //   // "Final Disc(" +
      //   // parseFloat(getExtraPer()).toFixed(2) +
      //   // ((getPath() === LISTINGPAGES.BID ||
      //   //   getPath() === LISTINGPAGES.MYBID ||
      //   //   (getPath() === "compare" &&
      //   //     (fsNow === LISTINGPAGES.BID || fsNow === LISTINGPAGES.MYBID))) &&
      //   //   getBidType() === DIAMOND_BID.TYPE.BLIND
      //   //   ? "+0.5"
      //   //   : "") +
      //   // "%)",
      //   accessor: "fnBack",
      //   id: "fnBack",
      //   Cell: ({ row }) => (
      //     <span className="themeColor">{parseFloat(row.original.calcDiscount).toFixed(2)}</span>
      //   ),
      // },
      // {
      //   Header: "Final Rate",
      //   accessor: "fnCtpr",
      //   id: "fnCtpr",
      //   Cell: ({ row }) => (
      //     <span className="themeColor">
      //       {parseFloat(row.original.calcPricePerCarat).toFixed(2)}
      //     </span>
      //   ),
      // },
      // {
      //   Header: "Final Value",
      //   accessor: "fnAmt",
      //   id: "fnAmt",
      //   Cell: ({ row }) => (
      //     <span className="themeColor">{parseFloat(row.original.calcAmount).toFixed(2)}</span>
      //   ),
      // },
      ...Columns.slice(index + 1, Columns.length).filter((el) => !['back', 'amt', 'ctPr'].includes(el.id)),
    ];
    setColumns(columns.filter((el) => el && el));

  }, [addDis, sysDis_with_positive]);
  const confirmStonePopupCounter = localStorage.getItem('confirmStonePopupCounter');

  return (
    <div className="confirmStonePopUp">
      <div className="d-flex align-items-center offerTopBlock offerWrapper mb-20">
        <Heading className="popupInnerTitle mr-40" title={<IntlMessages id="app.confirmStone" />}></Heading>
        {/* {HeadingCalc(checked)} */}
      </div>
      <div className={`conformPopupCommonTable ${props.popupClass ? props.popupClass : null}`} style={props.style}>
        <div className="searchResultTable tabInnerTableScroll" style={props.detail ? { height: 'auto' } : {}}>
          <Table
            columns={columns}
            data={props.checked}
            nodots
            areAllChecked={confirmStonePopupCounter <= 0 ? true : false}
            // areAllChecked={
            // props.diamondData.selectedRows['/diamond-list']?.length ===
            // props.diamondData.selectedRowIds[currentType]?.length
            //     ? true
            //     : false
            // }
            currentType={currentType}
            canSort={false}
            // nocheck={props.detail}
            noGrp
          />
        </div>
        <div className="DiamondDetailPopup mt-10">
          {/* {VALUES.map((x) => {
            console.log({ x });
            return (
              <div className="DiamondDetailPopupItem" key={x.title}>
                <span>{x.title}</span>
                <span>
                  {x.title === 'Avg. Discount(%)' || x.title === 'Final Discount(%)'
                    ? x.value > 0
                      ? `+${x.value}`
                      : x.value
                    : x.title === 'promotional Dis(%)'
                    ? props.location.pathname === '/show'
                      ? showDiscAmount > props.showDis?.maxAmt
                      : `${x.value} ($${Math.abs(parseFloat(props.showDis?.maxAmt).toFixed(2))})`
                      ? showDiscAmount < props.showDis?.maxAmt
                      : `${x.value} ($${parseFloat(showDiscAmount)})`
                      ? promDiscAmount > props.promotionDis?.maxAmt
                      : `${x.value} ($${Math.abs(parseFloat(props.promotionDis?.maxAmt).toFixed(2))})`
                      ? promDiscAmount < props.promotionDis?.maxAmt
                      : `${x.value} ($${parseFloat(promDiscAmount)})`
                    : x.value}
                </span>
              </div>
            );
          })} */}
          {VALUES.map((x) => {
            let valueToRender;

            if (x.title === 'Avg. Discount(%)' || x.title === 'Final Discount(%)') {
              if (x.value > 0) {
                valueToRender = `+${x.value}`;
              } else {
                valueToRender = x.value;
              }
            } else if (x.title === 'promotional Dis(%)') {
              if (props?.location?.pathname === '/show') {
                if (showDiscAmount > props.showDis?.maxAmt) {
                  valueToRender = `${x.value} ($${Math.abs(parseFloat(props.showDis?.maxAmt).toFixed(2))})`;
                } else if (!isNaN(showDiscAmount)) {
                  valueToRender = `${x.value} ($${parseFloat(showDiscAmount)})`;
                } else {
                  valueToRender = `${x.value} ($0.00)`;
                }
              } else {
                if (promDiscAmount > props.promotionDis?.maxAmt) {
                  valueToRender = `${x.value} ($${Math.abs(parseFloat(props.promotionDis?.maxAmt).toFixed(2))})`;
                } else if (!isNaN(promDiscAmount)) {
                  valueToRender = `${x.value} ($${parseFloat(promDiscAmount)})`;
                } else {
                  valueToRender = `${x.value} ($0.00)`;
                }
              }
            } else {
              valueToRender = x.value;
            }
            if (!Number(x.value) && (x.title === 'promo. Discount(%)' || x.title === 'promotional Dis(%)'))
              return <></>
            if (x.title === 'TERMS') {
              return (
                <div className="DiamondDetailPopupItem" key={x.title} style={{ width: '100%' }}>
                  <span>{x.title}</span>
                  <span>{valueToRender}</span>
                </div>
              );
            }
            return (
              <div className="DiamondDetailPopupItem" key={x.title}>
                <span>{x.title}</span>
                <span>{valueToRender}</span>
              </div>
            );
          })}
        </div>
        <div className="mt-10 finalCalDetail confirmListOption" style={props.detail ? { padding: '5px' } : {}}>
          {/* <div className="DiamondDetailPopup width-100"> */}

          {getcompany()}
          {/* {getinvoicedate()} */}
          <div className="commonTextArea from-group">
            <label>
              <IntlMessages id="app.comment" />
            </label>
            {gettextarea()}
          </div>
          {!props.detail && props?.location?.pathname !== '/show' && (
            <p className="offerNote">
              <b>
                <IntlMessages id="app.Note" />:
              </b>

              <IntlMessages id="app.confirmStoneNote" />
            </p>
          )}

          {props.location?.pathname === '/show' &&
            ((props.promotionDis?.data && props.promotionDis?.maxAmt) ||
              (props.showDis?.data && props.showDis?.maxAmt)) ? (
            <p className="showNote">
              <b>
                <IntlMessages id="app.Note" />:
              </b>

              <IntlMessages id="app.showStoneNote" />
            </p>
          ) : (
            <></>
          )}
        </div>
        <div className="sideBarPopupButton">
          <a className="commonButton" onClick={submit}>
            <IntlMessages id="app.confirmStone" />
          </a>
          <a className="commonButton" onClick={cancel}>
            <IntlMessages id="app.Cancel" />
          </a>
          {!props.detail && (
            <>
              <a
                className="commonButton"
                onClick={() =>
                  handleDownloadExcel(
                    {},
                    checked.map((x) => x.id),
                    () => { },
                  )
                }
              >
                <IntlMessages id="app.ExcelExport" />
              </a>
              <a
                className="commonButton"
                onClick={() => {
                  if (checked.length) sendEmail('stock');
                  else
                    OpenNotification({
                      type: 'error',
                      title: 'Please select any stone(s).',
                    });
                }}
              >
                <IntlMessages id="app.EmailStock" />
              </a>
              <a
                className="commonButton"
                onClick={() => {
                  if (checked.length) sendEmail('img');
                  else
                    OpenNotification({
                      type: 'error',
                      title: 'Please select any stone(s).',
                    });
                }}
              >
                <IntlMessages id="app.EmailPic" />
              </a>
              <SendEmailPopup
                sendEmail={email}
                onCancel={() => sendEmail('')}
                ids={checked.map((x) => x.id)}
                img={email === 'img'}
                isStock={email === 'stock'}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default ConfirmStonePopup;
