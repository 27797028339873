import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Dropdown, Drawer } from 'antd';
import Enquiry from '../../components/Enquiry';
import PersonalInformation from './PersonalInformation';
import CompanyInformation from './CompanyInformation';
import ManageUser from './ManageUsers';
import ManageAddress from './ManageAddress';
import ChangePassword from './ChangePassword';
import DiamondColumnListing from './ManageDiamondSequence';
import SavedSearch from '../SavedSearch/index';
import MyDemand from '../MyDemand/index';
import Offer from '../Offer';
import '../../components/common/Tab';
import DiamondList from '../../components/DiamondList/index';
import IntlMessages from '../../util/IntlMessages';
import NotificationSetting from './NotificationSetting';
import Profile from './Profile';
import { getPath, isMobile, LISTINGPAGES, getProjectSetting } from '../DiamondList/DiamondListFunctions';
import SelectStone from '../common/DiamondListing/SelectStone';
import { LOCAL_STORAGE_VAR, PROJECT_SETTINGS_TYPE } from '../../constants/Common';
import UtilService from '../../services/util';
import { get, isUndefined } from 'lodash';
import { AllStatusShow } from 'components/common/DiamondListing/AllStatusShow';

class Account extends Component {
  state = { visible: false, visible1: false, visible2: false, sum: {}, promotionDis: 0, showDis: 0 };

  componentDidMount() {
    getProjectSetting(PROJECT_SETTINGS_TYPE.PROMOTION_DISCOUNT, (cb) => {
      this.setState({ promotionDis: cb });
    });

    getProjectSetting(PROJECT_SETTINGS_TYPE.SHOW_DISCOUNT, (cb) => {
      this.setState({ showDis: cb });
    });
  }

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };
  showDrawer1 = () => {
    this.setState({
      visible1: true,
    });
  };
  showDrawer2 = () => {
    this.setState({
      visible2: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
      visible1: false,
      visible2: false,
    });
  };

  render() {
    const { location } = this.props;
    const profilePath = location.pathname === '/account/profile';
    const manageuserPath = location.pathname === '/account/profile/manage-user';
    const manageAddressPath = location.pathname === '/account/profile/manage-address';
    const diamondsequencePath = location.pathname === '/account/profile/diamond-sequence';
    const myProfile = location.pathname === '/account/profile';
    const USER_PERMIT = UtilService.getLocalStorageItem(`${LOCAL_STORAGE_VAR}-userPermissions`);
    const isGuest = localStorage.getItem(`${LOCAL_STORAGE_VAR}-guest`);
    const TABS = [
      // {
      //   path: "",
      //   title: <IntlMessages id="app.Summary" />,
      //   tab: <span>Summary</span>,
      // },
      get(USER_PERMIT, 'CART.view', false) && {
        path: '/cart',
        title: <IntlMessages id="app.my-cart" />,
        tab: <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />,
      },
      get(USER_PERMIT, 'WATCHLIST.view', false) && {
        path: '/watchlist',
        title: <IntlMessages id="app.my-watchlist" />,
        tab: <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />,
      },
      get(USER_PERMIT, 'REMINDER.view', false) && {
        path: '/reminder',
        title: <IntlMessages id="app.my-reminder" />,
        tab: <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />,
      },
      get(USER_PERMIT, 'OFFICE.view', false) && {
        path: '/office-list',
        title: <IntlMessages id="app.office-list" />,
        tab: <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />,
      },
      get(USER_PERMIT, 'FORMAL_MEET.view', false) && {
        path: '/formal-meet',
        title: 'My Formal Meet',
        tab: <DiamondList />,
      },
      get(USER_PERMIT, 'CONFIRM_STONE.view', false) && {
        path: '/confirm-list',
        title: <IntlMessages id="app.confirm-list" />,
        tab: <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />,
      },
      // get(USER_PERMIT, 'QUOTE.view', false) &&
      {
        path: '/quote-list',
        title: <IntlMessages id="app.MyOfferList" />,
        tab: <Offer onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />,
      },
      get(USER_PERMIT, 'NOTES.view', false) && {
        path: '/notes',
        title: <IntlMessages id="app.my-comment" />,
        tab: <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />,
      },
      // get(USER_PERMIT, 'HOLD.view', false) && {
      // {
      //   path: '/hold',
      //   title: <IntlMessages id="app.myHoldList" />,
      // tab: <DiamondList onSumChange={(sum, currentType) => this.setState({ sum, currentType })} />,
      // },
      get(USER_PERMIT, 'ENQUIRY.view', false) && {
        path: '/enquiry',
        title: <IntlMessages id="app.my-enquiry" />,
        tab: (
          <DiamondList
            onSumChange={(sum, currentType) => {
              this.setState({ sum, currentType });
            }}
          />
        ),
        // <Enquiry />,
      },
      get(USER_PERMIT, 'SAVE_SEARCH.view', false) &&
      !isGuest && { path: '/saved-search', title: <IntlMessages id="app.saved-search" />, tab: <SavedSearch /> },
      get(USER_PERMIT, 'DEMAND.view', false) &&
      !isGuest && {
        path: '/my-demand',
        title: <IntlMessages id="app.my-demand-tab" />,
        tab: <MyDemand />,
      },
      // get(USER_PERMIT, 'SHIPMENT.view', false) && {
      //   path: '/my-shipment',
      //   title: <IntlMessages id="app.shipment" />,
      //   tab: <MyDemand />,
      // },
      // {
      //   path: "/notification",
      //   title: <IntlMessages id="app.Notification" />,
      //   tab: <NotificationSetting />,
      // },
    ];
    const isaccount = window.location.pathname.split('/')[1] === 'account';

    return (
      <div>
        <div className="accountWrapper">
          <div className="accountTopBlock flex-wrap">
            <div className="accountUser">
              <h2 className="accountTitle">
                <IntlMessages id="app.YourAccount" />
              </h2>
              <AllStatusShow show={getPath() === LISTINGPAGES.SHOW} />
            </div>
            {isaccount && !isMobile() && getPath() !== LISTINGPAGES.CONFIRM ? (
              <SelectStone
                clearStyle={{ position: 'inherit' }}
                blockStyle={{
                  position: 'initial',
                  borderBottom: 'none',
                  paddingRight: '0px',
                }}
                sum={this.state.sum}
                currentType={this.props.location.pathname}
                promotionDis={this.state.promotionDis}
                showDis={this.state?.showDis}
                profilListing={isaccount ? 'profileStonSelect diamondListStone my_acc' : null}
              />
            ) : null}
          </div>
          <div className="customerSubTab">
            <ul className="accountTabBox">
              {TABS &&
                TABS.map((x, i) => {
                  if (x) {
                    return (
                      <Link to={'/account' + x.path}>
                        <li
                          key={i}
                          className={location.pathname === '/account' + x.path ? 'react-tabs__tab--selected' : null}
                        >
                          {x.title}
                        </li>
                      </Link>
                    );
                  }
                })}
              <li
                className={`${manageuserPath || manageAddressPath || diamondsequencePath || profilePath
                  ? 'react-tabs__tab--selected'
                  : ''
                  }`}
              >
                <Link to="/account/profile">
                  <IntlMessages id="app.my-account" />
                </Link>
              </li>
            </ul>
            {TABS &&
              TABS.map((x) => {
                if (x) {
                  return location.pathname === '/account' + x.path ? <>{x.tab}</> : <></>;
                }
              })}
            {myProfile && <Profile />}
            <Drawer
              title={false}
              onClose={this.onClose}
              visible={this.state.visible}
              wrapClassName="diamondListinSidebar profileSidebar"
            >
              <PersonalInformation />
            </Drawer>
            <Drawer
              title={false}
              onClose={this.onClose}
              visible={this.state.visible1}
              wrapClassName="diamondListinSidebar profileSidebar"
            >
              <CompanyInformation />
            </Drawer>
            {manageuserPath && <ManageUser />}
            <Drawer
              title={false}
              onClose={this.onClose}
              visible={this.state.visible2}
              wrapClassName="diamondListinSidebar profileSidebar"
            >
              <ChangePassword />
            </Drawer>
            {manageAddressPath && <ManageAddress />}
            {diamondsequencePath && <DiamondColumnListing />}
          </div>
        </div>
      </div>
    );
  }
}

export default connect((props) => {
  return props;
}, null)(withRouter(Account));
